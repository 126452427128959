@import "variables";

.bg-darkBlueTransparent {
    background: rgba(18, 30, 45, .9) !important;
}

.dialog-content {
    max-height: 90vh;
    /* Limits the height of the dialog to 80% of the viewport height */
    // overflow-y: auto;
    /* Enables vertical scrolling */
}

::ng-deep .primetabsQuick {
    background: rgba(18, 30, 45, .9) !important;
    justify-content: space-around;
}

::ng-deep .p-tabview-panels {
    background: rgba(18, 30, 45, .9) !important;
    color: white;

}

::ng-deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {

    background: rgba(18, 30, 45, 1) !important;
    color: white;
}

::ng-deep .p-tabview {
    background: rgba(18, 30, 45, .9) !important;
    border: none;
}

::ng-deep .p-tabview .p-tabview-nav {
    background: rgba(18, 30, 45, .9) !important;
}

::ng-deep .p-tabview .p-tabview-panel {
    background: rgba(18, 30, 45, .9) !important;
}

::ng-deep .primetabsQuick {
    height: 100%;
    /* Ensure the tab view uses the full height */
    display: flex;
    flex-direction: column;
}

::ng-deep .primetabsQuick .p-tabview-panel .details {
    flex: 1;
    /* Make the content area flexible */
    overflow-y: auto;
    /* Enable scrolling */
}

::ng-deep .primetabsQuick .__submit {
    position: sticky;
    /* Keep buttons fixed at the bottom */
    bottom: 0;
    z-index: 10;
}

::ng-deep .p-tabview-nav {
    /* Ensure the tabs header does not scroll */
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}



::ng-deep .p-tabview {
    .p-tabview-nav {
        li {
            .p-tabview-nav-link {
                font-size: 14px; // Font size
                padding: 0.7rem;
                font-weight: 400;
            }
        }
    }
}

::ng-deep .full-screen-dialog .mat-mdc-dialog-container {
    background-color: red !important;
    color: white;
    /* Optional: Adjust text color for contrast */
    border-radius: 8px;
    /* Optional: Add rounded corners */
}

::ng-deep .mat-typography {
    background-color: red !important;

}

.full-screen-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        @apply bg-semiDarkBlue text-white;
        // position: relative;

    }
}


.popup-stepper {
    position: relative;
    margin-top: 90px;

    * {
        [lang="en"] {

            font-family: "Basic-Sans", sans-serif !important;

        }

        [lang="ar"] {

            font-family: "Cairo", sans-serif !important;

        }
    }
}



.mat-stepper-vertical,
.mat-stepper-horizontal {
    background: #1c293b !important;
    color: white !important;
    font-family: unset !important; // Unset Material's default font family


    [lang="en"] {

        font-family: "Basic-Sans", sans-serif !important;

    }

    [lang="ar"] {

        font-family: "Cairo", sans-serif !important;

    }


    .mat-horizontal-stepper-header-container {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        background-color: #121e2d;
        width: 100%;
        overflow-y: scroll;
        .mat-step-header {
            flex-direction: column;
            justify-content: center;

            .mat-step-label.mat-step-label-active {
                transition: ease-in-out all 0.3s;
                font-size: 0.6rem;
                color: white;
                text-align: center;
            }

            .mat-step-label.mat-step-label-selected {
                font-size: 0.8rem;
                transition: ease-in-out all 0.3s;
                color: #f9c728 !important;
                text-align: center;
            }

        }
    }

    .mat-horizontal-content-container {
        margin: 3rem 0 2rem 0;
        padding: 0 0 8rem 0;
    }

}


.mat-step-header {
    pointer-events: none;
}

.mat-dialog-container {
    // height: 100%;
    // width: 100%;
    // max-width: 100% !important;
}

.mat-mdc-dialog-content {
    max-height: 100vh !important;
}


.otp-input {
    background-color: $inputFieldBackground !important;
    border: none !important;
    width: 70px !important;
    height: 50px !important;
    color: $white !important;

    @media (max-width: 400px) {
        width: 50px !important;
        height: 35px !important;
    }
}